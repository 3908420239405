import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, register } from '../../State/AUTH/Action';
const Register = () => {
    const navigate = useNavigate();

    const dispatch= useDispatch();
    const jwt = localStorage.getItem("jwt")
    const {auth}= useSelector(store=>store)


    useEffect(()=>{
        if(jwt){

            dispatch(getUser(jwt))
        }
    },[jwt, auth.jwt])

    const handleSubmit=(event)=>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userData= {
            firstName:data.get("firstName"),
            lastName:data.get("lastName"),
            email:data.get("email"),
            password:data.get("password"),

        }

        dispatch(register(userData))
        console.log('Userdata', userData);
    }
  return (
    <div>
        
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    fullWidth
                    autoComplete='given name' >

                    </TextField>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    fullWidth
                    autoComplete='given name' >

                    </TextField>

                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                    required
                    id='email'
                    name='email'
                    label='Email'
                    fullWidth
                    autoComplete='email' >

                    </TextField>

                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                    required
                    id='password'
                    name='password'
                    label='Password'
                    fullWidth
                    autoComplete='Password' >

                    </TextField>

                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button className='bg-[#9155FD] w-full' type='submit'
                     variant='contained'
                     size='large'
                     sx={{padding:".8rem 0", bgcolor:"#9155FD"}}
                    >Register</Button>

                </Grid>
            </Grid>
        </form>

        <div>
            <div className='py-3 flex ietms-center'>
                <p>If you have already acccount ?.</p>
                <Button className='ml-5'  size='small' onClick={()=>navigate("/login")}>Login</Button>
            </div>
        </div>
    </div>
  )
}

export default Register