export const FIND_PRODUCT_BY_ID_REQUEST = "FIND_PRODUCT_BY_ID_REQUEST";
export const FIND_PRODUCT_BY_ID_SUCCESS = "FIND_PRODUCT_BY_ID_SUCCESS";
export const FIND_PRODUCT_BY_ID_FAILURE = "FIND_PRODUCT_BY_ID_FAILURE";

export const FIND_PRODUCTS_REQUEST = "FIND_PRODUCTS_REQUEST";
export const FIND_PRODUCTS_SUCCESS = "FIND_PRODUCTS_SUCCESS";
export const FIND_PRODUCTS_FAILURE = "FIND_PRODUCTS_FAILURE";

export const DELETE_PRODUCTS_REQUEST = "DELETE_PRODUCTS_REQUEST";
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";
export const DELETE_PRODUCTS_FAILURE = "DELETE_PRODUCTS_FAILURE";

export const CREATE_PRODUCTS_REQUEST = "CREATE_PRODUCTS_REQUEST";
export const CREATE_PRODUCTS_SUCCESS = "CREATE_PRODUCTS_SUCCESS";
export const CREATE_PRODUCTS_FAILURE = "CREATE_PRODUCTS_FAILURE";
