import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import DeliveryAddressFrom from './DeliveryAddressFrom';
import Ordersummary from './Ordersummary';

const steps = ['Login', 'Delivery Address', 'Order Summary', 'Payment'];

export default function CheckOut() {
  const [activeStep, setActiveStep] = React.useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const querySearch = new URLSearchParams(location.search);
  const step = querySearch.get('step');

  React.useEffect(() => {
    const stepValue = step ? parseInt(step, 10) : 0;
    setActiveStep(stepValue)
    navigate(`${location.pathname}?step=${stepValue}`);
  }, [step, navigate, location.search]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const nextStep = prevActiveStep + 1;
      const updatedStep = nextStep < steps.length ? nextStep : prevActiveStep;
      navigate(`${location.pathname}?step=${updatedStep}`);

      return updatedStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const updatedStep = Math.max(prevActiveStep - 1, 0);
      navigate(`${location.pathname}?step=${updatedStep}`);

      return updatedStep;
    });
  };

  return (
    <div className='px-10:px-20'>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
          
            
            </Box>
            <div className='mt-10 '>
                {
                    step ===1?<DeliveryAddressFrom/>:<Ordersummary/>
                }
                
                </div>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}
