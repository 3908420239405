export const mainCarsoselData = [
    {
      image: "https://images.pexels.com/photos/443446/pexels-photo-443446.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      image: 'https://images.pexels.com/photos/386000/pexels-photo-386000.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      image: 'https://images.pexels.com/photos/413960/pexels-photo-413960.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      image: 'https://images.pexels.com/photos/1687845/pexels-photo-1687845.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      image: 'https://images.pexels.com/photos/417344/pexels-photo-417344.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      image: 'https://images.pexels.com/photos/259893/pexels-photo-259893.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      image: 'https://images.pexels.com/photos/716658/pexels-photo-716658.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
  ];
  